export function getSupplementaryAmount(data) {
  let SupplementaryAmount = 0
  data.Cn2CnFreightList.forEach((element) => {
    SupplementaryAmount += element.LocalDifference
  })
  data.ResponseList.forEach((element) => {
    SupplementaryAmount += element.LocalActualAmount
  })
  return SupplementaryAmount
}
